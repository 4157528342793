:root {
  --body-bg: #fafafa;
  --body-color: #7a7a7a;
  --body-font-weight: 400;
  --link-color: #0074d9;
  --link-color-hover: #005baa;
  --heading-color: #333;
  --heading-color-hover: #222;
  --hr-color: rgba(0, 0, 0, 0.1);
  --text-mid: #000000;
}
@media (prefers-color-scheme: dark) {
  :root {
    --body-bg: #212529;
    --body-color: #6c757d;
    --body-font-weight: 300;
    --link-color: #dee2e6;
    --link-color-hover: #0c75b7;
    --heading-color: #fff;
    --heading-color-hover: #0c75b7;
    --hr-color: rgba(255, 255, 255, 0.1);
    --text-mid: #ffffff;
  }
}

* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
@media (min-width: 30em) {
  html {
    font-size: 18px;
  }
}
body {
  margin: 0;
  padding: 1.5rem;
  font: var(--body-font-weight) 1rem/1.5 -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Droid Sans', 'Helvetica Neue', Arial, sans-serif;
  color: var(--body-color);
  background-color: var(--body-bg);
}
@media (min-width: 500px) {
  body {
    padding: 3rem;
  }
}

a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
a:hover {
  color: var(--link-color-hover);
  text-decoration: none;
  text-decoration-skip-ink: auto;
}
h1,
h2,
h3 {
  margin-top: 0;
  font-weight: 500;
}
h1 {
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
  line-height: 1;
}
h1 a {
  color: var(--heading-color);
}
h1 span {
  display: inline-block;
  font-weight: 300;
}
h2 {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--body-color);
}
h3 {
  margin-bottom: 0;
  font-size: 1.25rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.lead {
  font-size: 1.25rem;
  line-height: 1.3;
  color: var(--heading-color);
}
hr {
  width: 6rem;
  height: 1px;
  margin: 2.5rem 0;
  background-color: var(--hr-color);
  border: 0;
}
@media (min-width: 500px) {
  .masthead {
    max-width: 450px;
  }
}
.links {
  font-size: 1.5rem;
}
.links a {
  color: var(--heading-color);
  margin-right: 0.5rem;
}
.links a:hover {
  color: var(--heading-color-hover);
  text-decoration: none;
}
.footer {
  color: var(--body-color);
}

.badge-primary {
  background-color: #0c75b7;
}

.text-mid {
  color: var(--text-mid);
}